angular.module("app")
    .controller("asidePhotoNoteController", function($timeout, $scope, $rootScope, $uibModal, _mapFieldService, _view, _v, _apiService, _flashService, Notification,
                                                     _i18n, _mapAddTaskService, $q, gettextCatalog, _chartService, _logicService, _calendar, _mapService, _formatService) {

        $scope.$on('_view:urlReady', function() {
            if (_view.is.map() && _view.is.viewPhotoNote()) {
                initViewPhotoNote();
            }
        });

        $scope.$on('_view:closeAllScreens', function() {
            if (_view.is.viewPhotoNote()) $scope.closeViewPhotoNote();
        });

        $scope.$on('_view:viewPhotoNoteChanged', function() {
            initViewPhotoNote();
        });

        function initViewPhotoNote() {
            $scope.photoNote = null;
            $scope.isLoading = true;

            if (_view.is.viewPhotoNote()) {
                _apiService.getPhotoNote(_view.getCurrentAccount(), _view.getViewPhotoNote()).then(function (d) {
                    $scope.photoNote = d.data;
                    $scope.photoNote.activities = [];
                    _.each($scope.photoNote.results, result => {
                        var type = result.type;
                        var activity = _.find($scope.photoNote.activities, function(e) { return e.type.rawName.toUpperCase() == type.toUpperCase() });
                        if (_.isUndefined(activity)) {
                            activity = {
                                type: {
                                    rawName: type.toUpperCase(),
                                    name: _i18n.getString("task.activity." + type)
                                },
                                results: []
                            };
                            $scope.photoNote.activities.push(activity);
                        }
                        activity.results.push({
                            resultJson: angular.fromJson(result.resultJson),
                            media: result.media,
                            type: result.type,
                            id: result.id
                        });
                    });
                    $scope.photoNote.activities = _.sortBy($scope.photoNote.activities,  function(e){ return e.type.rawName;});
                    $scope.photoNote.dateStr = _formatService.formatDateAndTime($scope.photoNote.noteDate);
                    $scope.isLoading = false;
                }, function () {
                    $scope.isLoading = false;
                    Notification.error({message: _i18n.getString("common.notifications.apiError")});
                });
            } else {
                $scope.isLoading = false;
            }
        }

        function closeViewPhotoNote() {
            _v.change({set: {viewPhotoNote: undefined}});
        }

        $scope.closeViewPhotoNote = closeViewPhotoNote;
    });